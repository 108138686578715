import { Stimulus, Controller } from '#/layouts/js/stimulus'

Stimulus.register('delete-all', class extends Controller {
  confirm() {
    const deleteButton = document.querySelector('#delete-button')

    if (this.element.value == 'delete') {
      deleteButton.removeAttribute('disabled')
    } else {
      deleteButton.setAttribute('disabled', 'disabled')
    }
  }
})
